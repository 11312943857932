import { get, patch } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";

import {
  type GetShiftTimeProposalResponse,
  type UpdateShiftTimeProposalRequest,
  type UpdateShiftTimeProposalResponse,
} from "../types";
import {
  getShiftTimeProposalResponseSchema,
  updateShiftTimeProposalRequestSchema,
  updateShiftTimeProposalResponseSchema,
} from "./schema";

interface GetShiftTimeProposalParams {
  shiftTimeProposalId: string;
}

interface UpdateShiftTimeProposalParams {
  shiftTimeProposalId: string;
}

export function useGetShiftTimeProposal(
  params: GetShiftTimeProposalParams,
  options: UseQueryOptions<GetShiftTimeProposalResponse> = {}
) {
  const { shiftTimeProposalId } = params;

  return useQuery<GetShiftTimeProposalResponse>({
    queryKey: ["shiftTimeProposal", shiftTimeProposalId],
    queryFn: async () => {
      const { data } = await get({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/shift-time-proposals/${shiftTimeProposalId}`,
        responseSchema: getShiftTimeProposalResponseSchema,
      });

      return data;
    },
    ...options,
  });
}

export function useUpdateShiftTimeProposal(
  params: UpdateShiftTimeProposalParams,
  options: UseMutationOptions<
    UpdateShiftTimeProposalResponse,
    unknown,
    UpdateShiftTimeProposalRequest
  > = {}
) {
  const { shiftTimeProposalId } = params;
  return useMutation({
    mutationFn: async (request) => {
      const { data } = await patch({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/shift-time-proposals/${shiftTimeProposalId}`,
        requestSchema: updateShiftTimeProposalRequestSchema,
        responseSchema: updateShiftTimeProposalResponseSchema,
        data: request,
      });

      return data;
    },
    ...options,
  });
}
