import { patch } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { getQueryString } from "@src/appV2/lib/api";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { SHIFT_INVITES_API_URL } from "../constant";
import {
  type AcceptOrDeclineParams,
  type AcceptOrDeclineShiftInviteErrorResponse,
  acceptOrDeclineShiftInviteRequestSchema,
  type AcceptOrDeclineShiftInviteResponse,
  acceptOrDeclineShiftInviteResponseSchema,
} from "../types";

export function useAcceptOrDeclineShiftInvite(
  options: UseMutationOptions<
    AcceptOrDeclineShiftInviteResponse,
    AxiosError<AcceptOrDeclineShiftInviteErrorResponse>,
    AcceptOrDeclineParams
  > = {}
): UseMutationResult<AcceptOrDeclineShiftInviteResponse, AxiosError, AcceptOrDeclineParams> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: AcceptOrDeclineParams) => {
      const {
        status,
        shiftInviteId,
        isOverworkingShiftsAllowed = false,
        skipFacilityRequiredDocumentCheck = false,
      } = params;
      const queryString = getQueryString({
        isOverworkingShiftsAllowed,
        skipFacilityRequiredDocumentCheck,
      });
      const url = `${SHIFT_INVITES_API_URL}/${shiftInviteId}?${queryString}`;
      const requestBody = {
        data: { attributes: { status }, type: "shiftInvite", id: shiftInviteId },
      };
      const response = await patch({
        url,
        data: requestBody,
        requestSchema: acceptOrDeclineShiftInviteRequestSchema,
        responseSchema: acceptOrDeclineShiftInviteResponseSchema,
      });
      void queryClient.invalidateQueries({ queryKey: [SHIFT_INVITES_API_URL] });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.ACCEPT_OR_DECLINE_SHIFT_INVITE,
    },
    ...options,
  });
}
