import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type QueryClient, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { SHIFT_INVITES_API_URL } from "../constant";
import { type ShiftInvitesResponse, shiftInvitesResponseSchema, ShiftInviteStatus } from "../types";

const DateRangeFilterDto = z.object({
  lte: z.string().optional(),
  gte: z.string().optional(),
  gt: z.string().optional(),
});

const ShiftDetailsFilter = z.object({
  start: DateRangeFilterDto.optional(),
});

const GetShiftInviteFilter = z.object({
  shiftDetails: ShiftDetailsFilter.optional(),
});

type GetShiftInviteFilterType = z.infer<typeof GetShiftInviteFilter>;

interface GetShiftInvitesQueryParams {
  filterParams?: GetShiftInviteFilterType;
  options?: UseGetQueryOptions<ShiftInvitesResponse>;
}

export async function invalidateWorkerPendingShiftInvites(queryClient: QueryClient): Promise<void> {
  await queryClient.invalidateQueries({ queryKey: [SHIFT_INVITES_API_URL] });
}

export function useWorkerPendingShiftInvites(
  getPendingShiftInviteParams: GetShiftInvitesQueryParams = {}
): UseQueryResult<ShiftInvitesResponse> {
  const { filterParams, options } = getPendingShiftInviteParams;
  return useGetQuery({
    url: SHIFT_INVITES_API_URL,
    responseSchema: shiftInvitesResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PENDING_SHIFT_INVITES,
    },
    queryParams: {
      filter: { ...filterParams, status: ShiftInviteStatus.PENDING },
      sort: "expiresAt",
    },
    ...options,
  });
}
