import z from "zod";

const type = z.literal("shift-time-proposal");

export const shiftTimeProposalStatusSchema = z.enum([
  "PROPOSED",
  "CANCELLED",
  "ACCEPTED",
  "DECLINED",
  "APPLIED",
]);

export const shiftTimeProposalRelationshipsSchema = z.object({
  shift: z.object({
    data: z.object({
      type: z.literal("shift"),
      id: z.string(),
    }),
  }),
});

export const shiftTimeProposalAttributesSchema = z.object({
  proposedStartTime: z.string().datetime(),
  proposedEndTime: z.string().datetime(),
  status: shiftTimeProposalStatusSchema,
});

export const shiftTimeProposalDataSchema = z.object({
  type,
  id: z.string(),
  attributes: shiftTimeProposalAttributesSchema,
  relationships: shiftTimeProposalRelationshipsSchema,
});

export const getShiftTimeProposalResponseSchema = z.object({
  data: shiftTimeProposalDataSchema,
});

export const updateShiftTimeProposalAttributesSchema = z.object({
  status: shiftTimeProposalStatusSchema,
});

export const updateShiftTimeProposalRequestSchema = z.object({
  data: z.object({
    type,
    id: z.string(),
    attributes: updateShiftTimeProposalAttributesSchema,
  }),
});

export const updateShiftTimeProposalResponseSchema = z.object({
  data: shiftTimeProposalDataSchema,
});
