import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { type Shift, shiftSchema } from "../../Shift/types";

const agentUpcomingShiftsRequestSchema = z.object({
  upcoming: z.boolean(),
});

export type AgentUpcomingShiftsRequest = z.infer<typeof agentUpcomingShiftsRequestSchema>;

export function useWorkerUpcomingShifts(
  queryParams: AgentUpcomingShiftsRequest,
  options?: UseGetQueryOptions<Shift[]>
): UseQueryResult<Shift[]> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/calendar/agentShifts`,
    queryParams,
    requestSchema: agentUpcomingShiftsRequestSchema,
    responseSchema: z.array(shiftSchema),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_UPCOMING_SHIFTS_FAILURE,
    },
    ...options,
  });
}
