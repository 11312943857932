import { isDefined } from "@clipboard-health/util-ts";

import { useWorkerPendingShiftInvites } from "./useWorkerPendingShiftInvites";

// FIXME: There is no endpoint for getting a single shift invite
export function usePendingShiftInvite(shiftInviteId?: string) {
  const pendingShiftInvitesResponse = useWorkerPendingShiftInvites({
    options: {
      enabled: isDefined(shiftInviteId),
    },
  });
  const { data: shiftInvites, isSuccess } = pendingShiftInvitesResponse;
  return {
    ...pendingShiftInvitesResponse,
    data: isSuccess ? shiftInvites.data.find((invite) => invite.id === shiftInviteId) : undefined,
  };
}
